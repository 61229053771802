.sorting-box {
  background: #595959;

	padding: 15px;

	.ant-form-item .ant-form-item-label > label {
		color: white;
	}

  margin-bottom: 16px;

  width: 100%;

  .ant-btn-ready {
    background-color: #78de6d;
    color: white;
  }

  .sorting-id-input {
    background-color: white;
  }

  button {

    .ant-btn-icon {
      margin-right: 8px;
    }
  }
}

.ant-tour-buttons {
  display: none !important;
}