:root {
  --stepTextColor: rgba(255, 255, 255, 0.65);
  --stepActiveColor: #fff;
  --stepFinishColor: green;
}

.lang-selector {
  width: 100%;
  vertical-align: middle;
}

.lang-selector .ant-select-selector {
  border: none !important;
}

.language-selector-image {
  vertical-align: middle;
  padding-right: 10px;
  max-height: 20px;
}

.ant-steps {
  padding: 10%;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 15px;
}

.ant-steps .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
	color: var(--stepActiveColor);
}

.ant-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: var(--stepFinishColor);
}

.ant-steps .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title  {
  color: var(--stepTextColor);
}

.ant-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: var(--stepTextColor);
}

.ant-steps .ant-steps-item-wait .ant-steps-item-icon {
  border-color: var(--stepTextColor);
}

.ant-steps .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after,
.ant-steps .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  background: var(--stepTextColor);
}
