.ant-upload-drag {
  height: auto !important;
}

body {
  margin: 0;

}
/*solution for random antd ResizeObserver loop error (only test issue)*/
iframe#webpack-dev-server-client-overlay { 
  display: none !important
}


.item-columns {
  display: block;
  margin: auto;

  columns: 3;
  column-gap: 1rem;
}

.merged-box {
  opacity: 1;
  transform: scale(1);

  margin: 0 0 1rem;
  display: inline-block;
  width: 100%;
  text-align: center;

  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.merged-box:has(.faded) > .merged-title,
.merged-box:has(.faded) > .merged-button {
  opacity: .2;
  transform: scale(.9);
}

.merged-title,
.merged-button {
  padding: 10px;
}

.merged-button .sort-button {
  width: 100%;
}

.ticker-view {
  flex-direction: row; 
}

.signOut:hover {
  color: #1890ff !important;
  cursor: pointer;
}

.signOut svg {
  margin-bottom: 1px;
}

h1 {
  margin-top: 0;
}


.navigate-button-text {
  white-space: pre-line;
}

.sorting-item-package {
  transition: all .5s ease-in-out; 
}

.shared-profile {
  margin-left: 15px;
}

.package-comment {
  color: grey;
}

.ml-0 {
  margin-left: 0 !important;
}

.ant-typography mark {
  padding: 3px;
}

.button-container {
  width: fit-content;
}

.break-button {
  margin-left: 40px;
  width: 88%;
  margin-top: 50px;
}

aside {
  flex: 0 0 220px !important;
  max-width: 220px !important;
  min-width: 220px !important;
  width: 220px !important;
}

.draft-counter {
  margin-top: 50px;
}

aside.ant-layout-sider-collapsed {
  flex: 0 0 80px !important;
  max-width: 80px !important;
  min-width: 80px !important;
  width: 80px !important;
}

.ant-layout-sider-trigger {
  width: 220px !important;
}

aside.ant-layout-sider-collapsed .ant-layout-sider-trigger {
  width: 80px !important;
}

.ant-menu-item .anticon + span {
  margin-left: 5px;
}

.action-list {
  display: flex;
  flex-wrap: wrap;
  margin: -5px -5px -5px 0;

}

.action-list button {
  margin: 5px 5px 5px 0;

}

.action-list-button {
  flex: 1 0 100%;

}

.action-list-button > button {
  width: 100%;

}

.ant-upload-disabled button {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;

}

td.idSelenium {
  max-width: 150px;

}

td.descriptionSelenium {
  max-width: 345px;
  
}

td.commentSelenium {
  max-width: 345px;
  
}

td.storeIdWithExternalId {
  text-align: center;

  p {
    margin: 2px 0;
  }
}

.ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #e6f7ff;

}

.changelogTableSelenium .ant-table-cell {
  vertical-align: top;
}

.ant-btn-link:hover {
  cursor: pointer;
}

.font-xl, .font-xl label, .font-xl input {
  font-size: 24px !important;
}

.checkinPage .ant-form-item-explain-error {
  background: #f2c3c385;
  padding: 10px;
  font-weight: 500;
  border-radius: 5px;
  margin-left: -5px;
}

.checkinPage .bold {
  font-weight: 600 !important;
}

.checkinPage label, .barcodeModal label {
  font-size: 18px !important;
}

.checkinPage .ant-form-item-explain {
  font-size: 16px !important;
  color: red;
  padding: 5px 0 0 5px;
}

.infoBox {
  border-radius: 30px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  display: flex;
  flex: 1 1 0;
}

@media (max-width: 977px) {
  .item-columns {
    columns: 1;
  }
}

@media (min-width: 978px) and (max-width: 1200px) {
  .modalAddProductBox {
    flex-direction: column;
    align-items: baseline;
  }

  .item-columns {
    columns: 2;
    column-gap: .5rem;
  }
}

@media (min-width: 1920px) {
  .item-columns {
    columns: 4;
  }
}

.greyBox {
  background-color: rgba(99, 99, 99, 0.2);
}

.commentSection {
  margin-bottom: 0;
}

.barcodeModal {
  top: 20px;
}

.barcodeModal #missing_label .ant-radio-button-wrapper-checked {
  font-weight: 700;
  background: aliceblue;
}

.barcodeModal svg {
  width: 250px !important;
  height: auto;
}

.barcodeModal .cancelButton svg {
  width: 300px !important;
  height: auto;
}

.barcodeModal .cancelButton {
  margin-left: 70px;
  margin-bottom: 0;
}

.barcodeModal .quantityWrapper {
  flex-wrap: nowrap;
  align-items: center;
  margin-left: 30px;
}

.barcodeModal .cancelSection {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.ant-modal-footer .sort-button {
  padding: 10px 20px;
  font-weight: bold;
  font-size: 18px;
}

.ant-modal-footer .ant-btn-default {
  border-radius: 12px;
  font-size: 16px;
}

.ant-modal-title {
  font-size: 19px !important;
  text-align: center;
}

.packageList {
  display: flex;
  align-items: center;
}

.packageList .quantityWrapper {
  font-size: 45px;
  font-weight: 700;
  margin-right: 15px;
}

.okCondition {
  color: green;
  font-weight: 700;
}

.broken, .missing {
  color: red;
  font-weight: 700;
}

.packageList button {
  padding: 4px 15px !important;
  margin-top: 5px;
  border: 1px solid dodgerblue;
  margin-left: 0 !important;
  border-radius: 8px !important;
}

.checkinPage .infoBox .imageWrapper {
  display: flex;
}

.checkinPage .infoBox ul li {
  font-size: 18px;
  margin-bottom: 5px;
  list-style-type: disclosure-closed;
  position: relative;
  font-weight: 300;
}


.checkinPage .infoBox ul li ul li {
  list-style-type: circle;
}

.checkinPage .infoBox ul li ul {
  margin-top: 5px;
}

.checkinPage .infoBox img {
  height: 200px;
}

.conditionBtn {
  display: flex;
  text-align: center;
}

.conditionBtn .ant-radio-button-wrapper {
  margin-right: 15px;
  border: 1px solid lavender;
  border-radius: 9px;
  padding: 10px;
  height: auto;
}

.conditionBtn .ant-radio-button-wrapper:hover {
  color: black;
}

.conditionBtn .ant-radio-button-wrapper::before {
  display: none;
}

.conditionBtn .ant-radio-button-wrapper-checked {
  color: white;
}
.conditionBtn .default {
  padding: 10px 30px !important;
}

.conditionBtn .ok-button.ant-radio-button-wrapper-checked, 
.conditionBtn .ant-radio-button-wrapper-checked.default {
  background-color: green;
}

.conditionBtn .ant-radio-button-wrapper-checked {
  background-color: red;
}

.conditionInfo {
  border: 2px solid #1677ff;
  display: flex;
  flex-direction: column;
}

.conditionInfo h3, 
.conditionInfo p {
  margin-bottom: 5px;
  margin-top: 5px;
}

.quantityButton {
  font-size: 12px !important;
  border-radius: 10px;
}

.addProductNavButton .navigateBtn button {
  margin-left: 20px;
  margin-bottom: 0;
}

.addProductNavButton .navigateBtn button svg {
  height: auto;
}

.quantityButton.decrease {
  margin-right: 20px;
}

.quantityButton.increase {
  background: #2b9c2b;
  margin-left: 20px;
}

.quantityButton.increase:hover  {
  background-color: #2b9c2b !important;
}

.productInfo article {
  text-wrap: nowrap;
}

.label-radio-btn {
  display: flex;
  flex-wrap: nowrap;
}

.batch-button-wrapper button {
  margin: 0 10px;
  width: 100%;
}

.check-in-header {
  display: flex;
  justify-content: space-between;
}

.batch-button {
  flex-direction: column;
  font-size: 16px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
}

.batch-button:hover,
.batch-button.ant-btn-primary {
  transform: scale(1.1);

  transition: all .5s ease-in-out; 
}

.sorting-wrapper {
  display: flex;
  flex-direction: row;
}

.sorting-item {
  align-items: center;
  height: 100%;
}

@media (min-width: 1200px) and (max-width: 1380px) {
  .sorting-item {
    flex-direction: column;
    text-align: center;
  }
}

@media (max-width: 425px) {
  .sorting-item {
    flex-direction: column;
    text-align: center;
  }
}

.bin-badges .ant-badge {
  padding: 2px 8px;
  background-color: rgba(0, 0, 0, 0.1);
}

.sorting-button-wrapper {
  width: 100%;
  padding: 0;
} 

.sorting-item .item-wrapper {
  padding: 10px;
}

.sorting-item p {
  margin-top: 0;
}

.sorting-item .item-wrapper mark {
  padding: 5px 8px;
  font-size: 16px;
  border-radius: 10px;
  background-color: #fff8e1;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.sorting-button-wrapper div {
  width: 100%;
}

.sorting-button-wrapper div button {
  width: 100%;
}

.sort-button {
  background-color: #efffe6;
  color: #073b20;
  line-height: 1.5;
  border-radius: 12px;
  border-color: #c8e4d5;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.finish-button-group .navigateBtn button {
  margin-left: 0;
}

.sort-button:hover {
  background-color: #8cba71 !important;
  color: white !important;
  border-color: #8cba71 !important;
}

.sort-no-more-button, .sort-quantity-button {
  background-color: #f4fdff;
  color: #09344f;
  line-height: 1.5;
  border-radius: 12px;
  border-color: #cbe6e9;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.sort-no-more-button {
  background-color: rgba(0, 0, 0, 0.04);
  color: #000;
  border-color: #fff;
}

.bin-selector-btn {
  width: auto;
  align-self: right;
}

.sorting-table-footer {
  position: fixed;
  right: 0;
  bottom: 0;
  background: #011528;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 25px;

  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.sorting-table-footer button:disabled {
  color: gray !important;
}

.sorting-table-footer .search-form div {
  margin-bottom: 0;
}

.close-batch-btn {
  margin: auto;
  max-width: 20%;

  background-color: rgb(60, 110, 83);
  color: white;

  padding: 30px 50px;
}

.close-batch-btn:hover {
  background-color: rgb(80, 125, 100) !important;
  color: white !important;
}

.check-in-profile {
  width: 100%;
}

.check-in-logo {
  background: #001628;
  margin-right: 10px;
  min-width: 220px;
}

.bin-badges .ant-badge {
  text-wrap: nowrap;
  padding: 7px 10px;
  border-radius: 8px;
  background: lavender;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.bin-badges .ant-badge-status-text {
  font-size: 16px !important;
}

.ant-select-item-option {
  padding-left: 8px !important;
}

.sorted-items-list p {
  margin: 0;
  color: #1677ff;
}

.sorted-items-list .ant-list-item-action li:hover {
  cursor: pointer;
}