.uploader-row {
  margin-bottom: 21px;

}
  
td.idSelenium, td.identityCodeSelenium, td.sealCodeSelenium {
  max-width: 150px;

}

.uploader-row > span > .ant-upload-list {
  position: absolute !important; 

}

.ant-table-content {
  overflow: auto hidden;

}

.identityCodeSelenium .ant-typography {
  display: flex;

  margin: 0;
}

.identityCodeSelenium .ant-typography a {
  word-break: keep-all;
}
