.navigateBtn button {
  height: auto !important;
  margin-left: 40px;
  width: auto !important;
}

.navigateBtn .barcodeWrapper {
  font-size: 18px;
  text-transform: uppercase;
  padding-top: 10px;
}

.no-align {
  text-align: center;
}
